.collapse {
  background-color: #EBEBEB;
  color: #4E535B;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  font-family: arial;
  margin-top: 4px;
  margin-bottom: 4px;
}

.active, .collapse:hover {
  background-color: #DADADA;
}

.historycontent {
  padding: 0 18px;
  overflow: hidden;
  background-color: #F1F1F1;
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-family: arial;
  color: #2C9EDB;
  margin-left: 8px;
}

.notes {
  font-size: 18px;
  font-family: arial;
  color: #5F5F5F;
  margin-left: 20px;
}