  .sync-pending-test-status {
    color: #879596 !important;
  }
  .sync-passed-test-status {
    color: #1e8900 !important;
  }
  .sync-failed-test-status {
    color: #df3312 !important;
  }
  .sync-error-test-status {
    color: #df3312 !important;
  }
  .sync-running-test-status {
    color: #007dbc !important;
  }
  .in-progress-text-color {
    color: #007dbc !important;
  }
  .green-color-text {
    color: #1e8900 !important;
  }
  .red-color-text {
    color: #df3312 !important;
  }
  .grey-color-text {
    color: #879596 !important;
  }
  .blue-color-text {
    color: #007dbc !important;
  }
  .black-color-style {
    color: #000000 !important;
  }
  .white-color-text {
    color: #ffffff !important;
  }
  .nowrap-style {
    white-space: nowrap !important;
  }
  .align-center {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
  .status-container {
    position: relative;
  }
  .status-vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .light-gray-color-text {
    color: #778899 !important;
  }
  .dark-gray-color-text {
    color: #4c4c4c !important;
  }
  div.scrollstylecontainerlabs {
    max-height: 400px;
    overflow: scroll;
    font-size: medium;
  }