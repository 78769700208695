


.f-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
    margin-bottom:5px;
}

.f-item {
    margin-right: 5px;
    margin-left: 5px;
}

